@charset "UTF-8";
@keyframes blink_animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
リセット
/***********************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a, a:hover {
  text-decoration: none;
}

a > img, nav > img, li > img, div > img {
  width: 100%;
  height: auto;
}

a img {
  border: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

button {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .sp_only {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 3.73vw;
  }
  .pc_only {
    display: none !important;
  }
}

.clearfix:after {
  font-size: 0;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

body {
  position: absolute;
  background-color: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 3.73vw;
  }
}

body * {
  box-sizing: border-box;
}

div, h1, h2, h3, h4, h5, ul, ol, li, dl, dt, dd {
  position: relative;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

p, div, span {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
}

img,
a img {
  border: none;
  display: inline-block;
}

img {
  width: 100%;
  height: auto;
}

#wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  #wrapper {
    min-width: 100%;
    overflow: hidden;
  }
}

.sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
}

@keyframes blink_animation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  font-size: 1.25vw;
}

@media screen and (min-width: 1281px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 4.26vw;
    -webkit-text-size-adjust: 100%;
  }
}

body {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-feature-settings: "palt";
}

body.noscroll {
  overflow: hidden;
}

body.hide div, body.hide img, body.hide a, body.hide a:link, body.hide a:visited, body.hide h1, body.hide h2, body.hide h3, body.hide span, body.hide dl, body.hide dt, body.hide dd, body.hide ol, body.hide ul, body.hide li {
  transition: none !important;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

p {
  font-size: 1vw;
  line-height: 2;
  color: #333333;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 3.45vw;
  }
}

.satoshi {
  font-family: 'Satoshi', sans-serif;
}

header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  padding: 0 4.3%;
}

@media screen and (max-width: 768px) {
  header {
    padding: 0 5.33%;
    height: 15.3vw;
  }
}

.header-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-contents__inner {
  display: flex;
  align-items: center;
  top: 3.2vw;
}

.header-contents__logo {
  width: 3.94vw;
  top: 3.1vw;
}

@media screen and (min-width: 769px) {
  .header-contents__logo {
    left: 0.1vw;
  }
}

@media screen and (max-width: 768px) {
  .header-contents__logo {
    width: 43.28%;
    padding-top: 2.6vw;
  }
}

.header-contents__logo img {
  display: block;
}

.header-contents__logo a {
  position: relative;
}

.header-contents__logo a > div:nth-child(2) {
  width: 20vw;
  position: absolute;
  left: 0;
  top: -0.5vw;
  transition: clip-path 0.8s cubic-bezier(0.63, 0, 0.45, 1);
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}

@media screen and (max-width: 768px) {
  .header-contents__logo a > div:nth-child(2) {
    width: 42.67vw;
    top: -1.3vw;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.header-contents__logo a > div:nth-child(2):after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

@media screen and (min-width: 769px) {
  .header-contents__logo:hover a > div:nth-child(2) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.header-contents-list {
  display: flex;
  align-items: center;
}

.header-contents-list__item {
  font-size: 0.93vw;
  margin-right: 2.82vw;
}

.header-contents-list__item:last-child {
  margin-right: 3.6vw;
}

.header-contents-list__item a, .header-contents-list__item a:link, .header-contents-list__item a:visited {
  color: #35453c;
  letter-spacing: 0;
  -webkit-text-stroke: 0.06vw transparent;
  transition: color 0.3s ease-out,-webkit-text-stroke 0.3s ease-out;
}

@media screen and (min-width: 769px) {
  .header-contents-list__item:hover a, .header-contents-list__item:hover a:link, .header-contents-list__item:hover a:visited {
    -webkit-text-stroke: 0.065vw #01b577;
    color: #01b577;
  }
}

.header-contents-list__item.active a, .header-contents-list__item.active a:link, .header-contents-list__item.active a:visited {
  -webkit-text-stroke: 0.065vw #01b577;
  color: #01b577;
}

.header-contents-lang {
  font-size: 0.93vw;
  width: 2vw;
  height: 1.38vw;
  cursor: pointer;
}

.header-contents-lang:before, .header-contents-lang:after {
  content: " ";
  display: block;
  position: absolute;
  width: 0.44vw;
  height: 0.38vw;
  right: 0;
  top: 0.5vw;
  background-image: url(/common/img/header-lang-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  transition: opacity 0.5s ease-out;
}

.header-contents-lang:after {
  background-image: url(/common/img/header-lang-arrow-close.png);
  opacity: 0;
}

.header-contents-lang.active:before {
  opacity: 0;
}

.header-contents-lang.active:after {
  opacity: 1;
}

.header-contents-lang.active .header-contents-lang__jp {
  opacity: 0.5;
}

.header-contents-lang.active .header-contents-lang__en {
  transform: translateY(100%);
  opacity: 1;
  pointer-events: auto;
  -webkit-text-stroke: 0.06vw transparent;
}

.header-contents-lang__link {
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.6s cubic-bezier(0.43, 0.05, 0.17, 1), opacity 0.3s cubic-bezier(0.26, 0.06, 0, 1) 0.1s, color 0.3s ease-out;
}

.header-contents-lang__link.header-contents-lang__en {
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.header-contents-lang__link.header-contents-lang__en a, .header-contents-lang__link.header-contents-lang__en a:link, .header-contents-lang__link.header-contents-lang__en a:visited {
  transition: color 0.3s ease-out,-webkit-text-stroke 0.3s ease-out;
  display: block;
}

@media screen and (min-width: 769px) {
  .header-contents-lang__link.header-contents-lang__en.active:hover a, .header-contents-lang__link.header-contents-lang__en.active:hover a:link, .header-contents-lang__link.header-contents-lang__en.active:hover a:visited {
    -webkit-text-stroke: 0.065vw #01b577;
    color: #01b577;
  }
}

.header-btn {
  width: 10.44%;
  height: 9.4vw;
  position: absolute;
  right: 0;
  top: 3.2vw;
}

.header-btn.active .header-btn__inner > div {
  top: 1vw;
  transform: rotate(-30deg);
}

.header-btn.active .header-btn__inner > div:nth-child(2) {
  transform: rotate(30deg);
}

.header-btn__inner {
  height: 1.87vw;
  top: 50%;
  transform: translateY(-50%);
}

.header-btn__inner > div {
  width: 100%;
  height: 2px;
  background-color: #333333;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.6s ease-out, top 0.6s ease-out, bottom 0.6s ease-out;
}

.header-btn__inner > div:nth-child(2) {
  top: auto;
  bottom: 0;
}

.header-menu-sp {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transition: transform 0.7s cubic-bezier(0.63, 0, 0.45, 1);
  transform: translateY(-120%);
}

.header-menu-sp.active {
  transform: translateY(0);
}

.header-menu-sp__inner {
  padding: 0 5.33%;
  padding-top: 24.6vw;
}

.header-menu-sp-contents {
  height: calc(100vh - 24.6vw);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.header-menu-sp-contents.show .header-menu-sp-list__item,
.header-menu-sp-contents.show .header-menu-sp-bottom__item,
.header-menu-sp-contents.show .header-menu-sp-lang {
  opacity: 1;
}

.header-menu-sp-contents.hide .header-menu-sp-list__item,
.header-menu-sp-contents.hide .header-menu-sp-bottom__item,
.header-menu-sp-contents.hide .header-menu-sp-lang {
  transition: opacity 0.3s ease-out !important;
}

.header-menu-sp-list {
  margin-bottom: 12.6vw;
}

.header-menu-sp-list__item {
  padding: 5.1vw 0 5.3vw;
  border-bottom: 2px solid #f2f2f2;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.header-menu-sp-list__item:first-child {
  border-top: 2px solid #f2f2f2;
}

.header-menu-sp-list__item:nth-child(2) {
  transition: opacity 1s ease-out 0.05s;
}

.header-menu-sp-list__item:nth-child(3) {
  transition: opacity 1s ease-out 0.1s;
}

.header-menu-sp-list__item:nth-child(4) {
  transition: opacity 1s ease-out 0.15s;
}

.header-menu-sp-list__item:nth-child(4) dd:nth-child(2), .header-menu-sp-list__item:nth-child(4) dd:nth-child(7), .header-menu-sp-list__item:nth-child(4) dd:nth-child(14), .header-menu-sp-list__item:nth-child(4) dd:nth-child(15) {
  flex: 0 0 100%;
}

.header-menu-sp-list__item:nth-child(4) dd:nth-child(3) {
  padding-left: 1.7em;
  text-indent: -0.7em;
  line-height: 1.08;
}

.header-menu-sp-list__item:nth-child(4) dd:nth-child(5) {
  margin-bottom: 4.6vw;
}

.header-menu-sp-list__item:nth-child(4) dd:nth-child(5), .header-menu-sp-list__item:nth-child(4) dd:nth-child(8), .header-menu-sp-list__item:nth-child(4) dd:nth-child(10), .header-menu-sp-list__item:nth-child(4) dd:nth-child(12) {
  padding-left: 1em;
}

@media screen and (min-width: 769px) {
  .header-menu-sp-list__item:nth-child(4) dd:nth-child(6) {
    margin-top: -4.4vw;
  }
}

.header-menu-sp-list__item:nth-child(4) dd:nth-child(14) {
  padding-top: 1.5vw;
  margin-bottom: 5.3vw;
}

.header-menu-sp-list__item:nth-child(5) {
  transition: opacity 1s ease-out 0.2s;
}

.header-menu-sp-list__item:nth-child(6) {
  transition: opacity 1s ease-out 0.25s;
}

.header-menu-sp-list__item .header-menu-sp-list__inner {
  height: 4.5vw;
  overflow: hidden;
  transition: height 0.5s ease-out;
}

.header-menu-sp-list__item dl {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.header-menu-sp-list__item dt {
  font-size: 4.55vw;
  font-weight: bold;
  -webkit-box-flex: 0;
  flex: 0 0 100%;
}

.header-menu-sp-list__item dt.header-menu-sp-list__pull {
  margin-bottom: 5vw;
}

.header-menu-sp-list__item dt.header-menu-sp-list__pull:before {
  content: " ";
  width: 3.2vw;
  height: 3.2vw;
  position: absolute;
  right: 0;
  top: 50%;
  transition: opacity 0.3s ease-out;
  transform: translateY(-50%);
  display: block;
  background-image: url(/common/img/pulldown-icon.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
}

.header-menu-sp-list__item dt.header-menu-sp-list__pull:after {
  content: " ";
  width: 3.2vw;
  height: 3.2vw;
  position: absolute;
  right: 0;
  top: 50%;
  transition: opacity 0.3s ease-out;
  transform: translateY(-50%);
  display: block;
  background-image: url(/common/img/pulldown-icon-close.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  opacity: 0;
}

.header-menu-sp-list__item dt.active.header-menu-sp-list__pull:before {
  opacity: 0;
}

.header-menu-sp-list__item dt.active.header-menu-sp-list__pull:after {
  opacity: 1;
}

.header-menu-sp-list__item dd {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  font-size: 3.2vw;
  color: #666666;
  margin-bottom: 3.4vw;
}

.header-menu-sp-list__item dd:nth-last-child(2), .header-menu-sp-list__item dd:last-child {
  margin-bottom: 0;
}

.header-menu-sp-list__item dd a, .header-menu-sp-list__item dd a:link, .header-menu-sp-list__item dd a:visited {
  color: #666666;
}

.header-menu-sp-list__item dd:before {
  content: ">";
  padding-right: 0.25em;
  transform: scaleX(0.7);
}

.header-menu-sp-bottom {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 13.5vw;
}

.header-menu-sp-bottom__item {
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  font-size: 3.2vw;
  margin-bottom: 5vw;
  transition: opacity 1s ease-out 0.3s;
  opacity: 0;
}

.header-menu-sp-bottom__item:nth-child(2) {
  transition: opacity 1s ease-out 0.35s;
}

.header-menu-sp-bottom__item:nth-child(3) {
  transition: opacity 1s ease-out 0.4s;
}

.header-menu-sp-bottom__item:nth-child(4) {
  transition: opacity 1s ease-out 0.45s;
}

.header-menu-sp-bottom__item:nth-last-child(2), .header-menu-sp-bottom__item:last-child {
  margin-bottom: 0;
}

.header-menu-sp-bottom__item:before {
  content: ">";
  padding-right: 0.25em;
}

.header-menu-sp-lang {
  width: calc(100% - 0.6vw);
  height: 12vw;
  border-radius: 12vw;
  border: 0.3vw solid #333333;
  color: #000;
  margin-bottom: 43.5vw;
  outline: none;
  appearance: none;
  text-align: left;
  font-size: 3.5vw;
  background-image: url(/common/img/header-lang-arrow-sp.png);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 2.27vw auto;
  transition: opacity 1s ease-out 0.5s;
  background-color: #fff;
  opacity: 0;
  position: relative;
  padding-left: 42.5vw;
}

.header-menu-sp-lang__link {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 11.4vw;
}

.header-menu-sp-lang__link a, .header-menu-sp-lang__link a:link, .header-menu-sp-lang__link a:visited {
  display: block;
}

footer {
  width: 100%;
  background-color: #f0f0f0;
  position: relative;
}

.pagetop {
  width: 3.75vw;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 1.8%;
  bottom: 2.1vw;
  z-index: 5;
  background-color: #01b577;
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .pagetop:hover {
    animation: blink_animation 0.6s ease-out;
  }
}

@media screen and (max-width: 768px) {
  .pagetop {
    width: 12.8%;
    position: absolute;
    left: 50%;
    right: auto;
    top: -6vw;
    bottom: auto;
    transform: translateX(-50%);
  }
}

.pagetop img {
  display: block;
}

.pagetop:before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.footer-inner {
  width: 91.25%;
  margin: 0 auto 6.1vw;
  padding-top: 7.24vw;
}

@media screen and (max-width: 768px) {
  .footer-inner {
    width: 89.33%;
    margin: 0 auto 7.5vw;
    padding-top: 19.8vw;
  }
}

.footer-contents {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.footer-contents__area {
  -webkit-box-flex: 0;
  flex: 0 0 65.06%;
  margin-top: 0.3em;
  display: flex;
}

@media screen and (max-width: 768px) {
  .footer-contents__area {
    flex-wrap: wrap;
    flex: 0 0 100%;
    margin-bottom: 14.4vw;
  }
}

.footer-contents__company {
  -webkit-box-flex: 0;
  flex: 0 0 30.13%;
}

@media screen and (max-width: 768px) {
  .footer-contents__company {
    flex: 0 0 100%;
  }
}

.footer-company__logo {
  width: 13.32vw;
  margin-bottom: 2.46vw;
}

@media screen and (min-width: 769px) {
  .footer-company__logo:hover {
    animation: blink_animation 0.6s ease-out;
  }
}

@media screen and (max-width: 768px) {
  .footer-company__logo {
    width: 39.1%;
    margin: 0 auto 5.5vw;
  }
}

.footer-company__data {
  font-size: 0.875vw;
  line-height: 2.14;
  margin-bottom: 4.1vw;
}

@media screen and (max-width: 768px) {
  .footer-company__data {
    font-size: 3.2vw;
    line-height: 1.41;
    text-align: center;
    margin-bottom: 6.5vw;
  }
}

.footer-company__copyright {
  font-size: 0.875vw;
}

@media screen and (max-width: 768px) {
  .footer-company__copyright {
    font-size: 2.4vw;
    text-align: center;
  }
}

.footer-contents-list {
  -webkit-box-flex: 0;
  flex: 0 0 66.73%;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .footer-contents-list {
    flex: 0 0 50%;
  }
}

.footer-contents-list:nth-child(1) .footer-contents-list__item {
  flex: 0 0 50%;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(1) .footer-contents-list__item {
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(1) .footer-contents-list__item:nth-child(3) dt {
    margin-bottom: 1.9vw;
  }
}

.footer-contents-list:nth-child(1) .footer-contents-list__item:last-child dd {
  color: #000;
}

.footer-contents-list:nth-child(1) .footer-contents-list__item:last-child dd a, .footer-contents-list:nth-child(1) .footer-contents-list__item:last-child dd a:link, .footer-contents-list:nth-child(1) .footer-contents-list__item:last-child dd a:visited {
  color: #000;
}

.footer-contents-list:nth-child(2) {
  flex: 0 0 33.36%;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) {
    flex: 0 0 50%;
  }
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dt {
  flex: 0 0 100%;
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd {
  flex: 0 0 100%;
  padding-left: 1em;
  text-indent: -0.62em;
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column {
  flex: 0 0 47.31%;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column {
    flex: 0 0 100%;
    line-height: 1.3;
    margin-bottom: 1.5vw;
  }
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-indent {
  padding-left: 1.7em;
  text-indent: -0.5em;
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-2 {
  margin-bottom: 1.9vw;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-2 {
    margin-bottom: 1.5vw;
  }
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-3 {
  margin-top: -0.75vw;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-3 {
    margin-top: 0;
    margin-bottom: 5.5vw;
  }
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-5 {
  margin-bottom: 1.5vw;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column.footer-list-item__column-5 {
    margin-bottom: 2vw;
  }
}

.footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column-6 {
  margin-bottom: 1.9vw;
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) .footer-contents-list__item dd.footer-list-item__column-6 {
    margin-top: 4vw;
    margin-bottom: 3.3vw;
  }
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(2) .footer-contents-list__item dd:nth-child(6) {
    margin-bottom: 4.5vw !important;
  }
}

@media screen and (max-width: 768px) {
  .footer-contents-list:nth-child(3) {
    flex: 0 0 100%;
    padding-top: 13.1vw;
  }
  .footer-contents-list:nth-child(3) .footer-list-item__inner dd {
    flex: 0 0 50%;
    color: #000;
  }
  .footer-contents-list:nth-child(3) .footer-list-item__inner dd a, .footer-contents-list:nth-child(3) .footer-list-item__inner dd a:link, .footer-contents-list:nth-child(3) .footer-list-item__inner dd a:visited {
    color: #000;
  }
}

.footer-contents-list__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.44vw;
}

@media screen and (max-width: 768px) {
  .footer-contents-list__item {
    margin-bottom: 7.5vw;
  }
}

.footer-contents-list__item:nth-child(5) .footer-list-item__inner dd {
  flex: 0 0 100%;
}

.footer-contents-list__item:last-child {
  margin-bottom: 0;
}

.footer-contents-list__item:last-child .footer-list-item__inner:nth-child(2) dd {
  flex: 0 0 100%;
}

.footer-list-item__inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.footer-list-item__inner dt {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  font-size: 1vw;
  letter-spacing: 0.05em;
  font-weight: bold;
  margin-bottom: 1.8vw;
}

@media screen and (max-width: 768px) {
  .footer-list-item__inner dt {
    font-size: 3.75vw;
    margin-bottom: 6.5vw;
  }
}

.footer-list-item__inner dt a, .footer-list-item__inner dt a:link, .footer-list-item__inner dt a:visited {
  color: #000;
}

@media screen and (min-width: 769px) {
  .footer-list-item__inner dt a:hover, .footer-list-item__inner dt a:link:hover, .footer-list-item__inner dt a:visited:hover {
    animation: blink_animation 0.6s ease-out;
  }
}

.footer-list-item__inner dd {
  -webkit-box-flex: 0;
  flex: 0 0 45.11%;
  font-size: 0.875vw;
  margin-bottom: 1.05vw;
  color: #666666;
}

@media screen and (max-width: 768px) {
  .footer-list-item__inner dd {
    flex: 0 0 100%;
    font-size: 3.45vw;
    margin-bottom: 2.8vw;
    padding-left: 0;
  }
}

.footer-list-item__inner dd:nth-child(2n+1) {
  flex: 0 0 calc(100% - 45.11%);
}

@media screen and (max-width: 768px) {
  .footer-list-item__inner dd:nth-child(2n+1) {
    flex: 0 0 100%;
  }
}

.footer-list-item__inner dd:before {
  content: ">";
  padding-right: 0.25em;
  transform: scaleX(0.7);
  display: inline-block;
}

.footer-list-item__inner dd a, .footer-list-item__inner dd a:link, .footer-list-item__inner dd a:visited {
  color: #666666;
  text-decoration: none;
}

@media screen and (min-width: 769px) {
  .footer-list-item__inner dd a:hover, .footer-list-item__inner dd a:link:hover, .footer-list-item__inner dd a:visited:hover {
    animation: blink_animation 0.6s ease-out;
  }
}

.footer-bottom {
  width: 100%;
  overflow: hidden;
}

.footer-bottom__pic {
  width: 100%;
  height: 17.313vw;
  display: flex;
  overflow: hidden;
}

.footer-bottom__pic div {
  height: 100%;
  transition: transform 1s cubic-bezier(0.95, 0.1, 0.23, 0.99);
  transition-delay: calc(var(--move-delay)*0.07s);
  transform: translateY(-100%);
}

.footer-bottom__pic div img {
  width: auto;
  height: 100%;
}

.footer-bottom__pic.show div {
  transform: translateY(0);
}
