@import "_reset";
@import "_property";//独自便利プロパティ集

html{
    font-size: 1.25vw;

    @include MinScale(1281px){
        font-size:16px;
    }

    @include SpScale{
      font-size: 4.26vw;
      -webkit-text-size-adjust: 100%;
    }
}

body{
    font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-feature-settings: "palt";
    
    &.noscroll{
        overflow: hidden;
    }
    
    &.hide{
        div,img,a,a:link,a:visited,h1,h2,h3,span,dl, dt, dd, ol, ul, li{
            transition: none !important;
        }
    }
}

.wrapper{
    width: 100%;
    overflow: hidden;
}

p{
    font-size: 1vw;
    line-height: 2;
    color: #333333;

    @include SpScale{
        font-size: 3.45vw;
    }
}

.satoshi{
    font-family: 'Satoshi', sans-serif;
}

header{
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    padding: 0 4.3%;

    @include SpScale{
        padding: 0 5.33%;
        height: 15.3vw;
    }
}

.header-contents{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header-contents__inner{
    display: flex;
    align-items: center;
    top: 3.2vw;
}

.header-contents__logo{
    width: 3.94vw;
    top: 3.1vw;

    @include PcScale{
        left: 0.1vw;
    }

    @include SpScale{
        width: 43.28%;
        padding-top: 2.6vw;
    }

    img{
        display: block;
    }

    a{
        position: relative;

        &>div{
            &:nth-child(2){
                width: 20vw;
                position: absolute;
                left: 0;
                top: -0.5vw;
                transition:clip-path 0.8s cubic-bezier(0.63, 0, 0.45, 1);
                clip-path: polygon(0 0,0 0,0 100%,0 100%);

                @include SpScale{
                    width: 42.67vw;
                    top: -1.3vw;
                    clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
                }

                &:after{
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top:0;
                    position: absolute;
                }
            }
        }
    }

    &:hover{
        @include PcScale{
            a{
                &>div{
                    &:nth-child(2){
                        clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
                    }
                }
            }
        }
    }
}

.header-contents-list{
    display: flex;
    align-items: center;
}

.header-contents-list__item{
    font-size: 0.93vw;
    margin-right: 2.82vw;

    &:last-child{
        margin-right: 3.6vw;
    }

    a,a:link,a:visited{
        color: #35453c;
        letter-spacing: 0;
        -webkit-text-stroke: 0.06vw transparent;
        transition:color 0.3s ease-out,-webkit-text-stroke 0.3s ease-out;
    }

    &:hover{
        @include PcScale{
            a,a:link,a:visited{
                -webkit-text-stroke: 0.065vw #01b577;
                color: #01b577;
            }
        }
    }

    &.active{
        a,a:link,a:visited{
            -webkit-text-stroke: 0.065vw #01b577;
            color: #01b577;
        }
    }
}

.header-contents-lang{
    font-size: 0.93vw;
    width: 2vw;
    height: 1.38vw;
    cursor: pointer;

    &:before,
    &:after{
        content: " ";
        display: block;
        position: absolute;
        width: 0.44vw;
        height: 0.38vw;
        right: 0;
        top: 0.5vw;
        background-image:url(/common/img/header-lang-arrow.png) ;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center top;
        transition: opacity 0.5s ease-out;
    }

    &:after{
        background-image:url(/common/img/header-lang-arrow-close.png) ;
        opacity: 0;
    }

    &.active{

        &:before{
            opacity: 0;
        }

        &:after{
            opacity: 1;
        }

        .header-contents-lang__jp{
            opacity: 0.5;
        }

        .header-contents-lang__en{
            transform: translateY(100%);
            opacity: 1;
            pointer-events: auto;
            -webkit-text-stroke: 0.06vw transparent;
        }
    }
}

.header-contents-lang__link{
    text-align: left;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform 0.6s cubic-bezier(.43,.05,.17,1),opacity 0.3s cubic-bezier(.26,.06,0,1) 0.1s,color 0.3s ease-out;

    &.header-contents-lang__en{
        width: 100%;
        opacity: 0;
        pointer-events: none;

        a,a:link,a:visited{
            transition: color 0.3s ease-out,-webkit-text-stroke 0.3s ease-out;
            display: block;
        }

        &.active{
            &:hover{
                @include PcScale{
    
                    a,a:link,a:visited{
                        -webkit-text-stroke: 0.065vw #01b577;
                        color: #01b577;
                    }
                }
            }
        }
    }
}

.header-btn{
    width: 10.44%;
    height: 9.4vw;
    position: absolute;
    right: 0;
    top: 3.2vw;

    &.active{
        .header-btn__inner{
            &>div{
                top: 1vw;
                transform: rotate(-30deg);

                &:nth-child(2){
                    transform: rotate(30deg);
                }
            }
        }
    }
}

.header-btn__inner{
    height: 1.87vw;
    top: 50%;
    transform: translateY(-50%);

    &>div{
        width: 100%;
        height:2px;
        background-color: #333333;
        position: absolute;
        left: 0;
        top: 0;
        transition: transform 0.6s ease-out, top 0.6s ease-out, bottom 0.6s ease-out;

        &:nth-child(2){
            top: auto;
            bottom: 0;
        }
    }
}

.header-menu-sp{
    width:100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    transition: transform 0.7s cubic-bezier(0.63, 0, 0.45, 1);
    transform: translateY(-120%);

    &.active{
        transform: translateY(0);
    }
}

.header-menu-sp__inner{
    padding: 0 5.33%;
    padding-top: 24.6vw;
}

.header-menu-sp-contents{
    height: calc(100vh - 24.6vw);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &.show{
        .header-menu-sp-list__item,
        .header-menu-sp-bottom__item,
        .header-menu-sp-lang{
            opacity: 1;
        }
    }

    &.hide{
        .header-menu-sp-list__item,
        .header-menu-sp-bottom__item,
        .header-menu-sp-lang{
            transition: opacity 0.3s ease-out !important;
        }
    }
}

.header-menu-sp-list{
    margin-bottom: 12.6vw;
}

.header-menu-sp-list__item{
    padding: 5.1vw 0 5.3vw;
    border-bottom: 2px solid #f2f2f2;
    transition: opacity 1s ease-out;
    opacity: 0;

    &:first-child{
        border-top: 2px solid #f2f2f2;
    }

    &:nth-child(2){
        transition: opacity 1s ease-out 0.05s;
    }

    &:nth-child(3){
        transition: opacity 1s ease-out 0.1s;
    }

    &:nth-child(4){
        transition: opacity 1s ease-out 0.15s;

        dd{
            &:nth-child(2),
            &:nth-child(7),
            &:nth-child(14),
            &:nth-child(15){
                flex: 0 0 100%;
            }

            &:nth-child(3){
                padding-left: 1.7em;
                text-indent: -0.7em;
                line-height: 1.08;
            }

            &:nth-child(5){
                margin-bottom: 4.6vw;
            }

            &:nth-child(5),
            &:nth-child(8),
            &:nth-child(10),
            &:nth-child(12){
                padding-left: 1em;
            }

            &:nth-child(6){
                @include PcScale{
                    margin-top: -4.4vw;
                }
            }

            &:nth-child(14){
                padding-top: 1.5vw;
                margin-bottom: 5.3vw;
            }
        }
    }

    &:nth-child(5){
        transition: opacity 1s ease-out 0.2s;
    }

    &:nth-child(6){
        transition: opacity 1s ease-out 0.25s;
    }

    .header-menu-sp-list__inner{
        height: 4.5vw;
        overflow: hidden;
        transition: height 0.5s ease-out;
    }

    dl{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

    }

    dt{
        font-size: 4.55vw;
        font-weight: bold;
        -webkit-box-flex: 0;
        flex: 0 0 100%;

        &.header-menu-sp-list__pull{
            margin-bottom: 5vw;

            &:before{
                content: " ";
                width: 3.2vw;
                height: 3.2vw;
                position: absolute;
                right: 0;
                top: 50%;
                transition: opacity 0.3s ease-out;
                transform: translateY(-50%);
                display: block;
                background-image: url(/common/img/pulldown-icon.png);
                background-repeat: no-repeat;
                background-position: center top;
                background-size: 100% auto;
            }

            &:after{
                content: " ";
                width: 3.2vw;
                height: 3.2vw;
                position: absolute;
                right: 0;
                top: 50%;
                transition: opacity 0.3s ease-out;
                transform: translateY(-50%);
                display: block;
                background-image: url(/common/img/pulldown-icon-close.png);
                background-repeat: no-repeat;
                background-position: center top;
                background-size: 100% auto;
                opacity: 0;
            }
        }

        &.active{

            &.header-menu-sp-list__pull{
                &:before{
                    opacity: 0;
                }

                &:after{
                    opacity: 1;
                }
            }
        }
    }

    dd{
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        font-size: 3.2vw;
        color: #666666;
        margin-bottom: 3.4vw;

        &:nth-last-child(2),
        &:last-child{
            margin-bottom: 0;
        }

        a,a:link,a:visited{
            color: #666666;
        }

        &:before {
            content: ">";
            padding-right: 0.25em;
            transform: scaleX(0.7);
        }
    }
}

.header-menu-sp-bottom{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 13.5vw;
}


.header-menu-sp-bottom__item{
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    font-size: 3.2vw;
    margin-bottom: 5vw;
    transition: opacity 1s ease-out 0.3s;
    opacity: 0;

    &:nth-child(2){
        transition: opacity 1s ease-out 0.35s;
    }

    &:nth-child(3){
        transition: opacity 1s ease-out 0.4s;
    }

    &:nth-child(4){
        transition: opacity 1s ease-out 0.45s;
    }

    &:nth-last-child(2),
    &:last-child{
        margin-bottom: 0;
    }

    &:before {
        content: ">";
        padding-right: 0.25em;
    }
}

.header-menu-sp-lang{
    width: calc(100% - 0.6vw);
    height: 12vw;
    border-radius: 12vw;
    border: 0.3vw solid #333333;
    color: #000;
    margin-bottom: 43.5vw;
    outline: none;
    appearance: none;
    text-align: left;
    font-size: 3.5vw;
    background-image:url(/common/img/header-lang-arrow-sp.png);
    background-repeat: no-repeat;
    background-position: 96% center;
    background-size: 2.27vw auto;
    transition: opacity 1s ease-out 0.5s;
    background-color: #fff;
    opacity: 0;
    position: relative;
    padding-left: 42.5vw;
}

.header-menu-sp-lang__link{
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 11.4vw;

    a,a:link,a:visited{
        display: block;
    }
}

footer{
    width: 100%;
    background-color: #f0f0f0;
    position: relative;
}

.pagetop{
    width: 3.75vw;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    right: 1.8%;
    bottom: 2.1vw;
    z-index: 5;
    background-color: #01b577;
    overflow: hidden;
    @include blink;

    @include SpScale{
        width: 12.8%;
        position: absolute;
        left: 50%;
        right: auto;
        top: -6vw;
        bottom: auto;
        transform: translateX(-50%);
    }

    img{
        display: block;
    }

    &:before{
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 50%;
    }
}

.footer-inner{
    width: 91.25%;
    margin: 0 auto 6.1vw;
    padding-top: 7.24vw;

    @include SpScale{
        width: 89.33%;
        margin: 0 auto 7.5vw;
        padding-top: 19.8vw;
    }
}

.footer-contents{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.footer-contents__area{
    -webkit-box-flex: 0;
    flex: 0 0 65.06%;
    margin-top: 0.3em;
    display: flex;

    @include SpScale{
        flex-wrap: wrap;
        flex: 0 0 100%;
        margin-bottom: 14.4vw;
    }
}

.footer-contents__company{
    -webkit-box-flex: 0;
    flex: 0 0 30.13%;

    @include SpScale{
        flex: 0 0 100%;
    }
}

.footer-company__logo{
    width: 13.32vw;
    margin-bottom: 2.46vw;
    @include blink;

    @include SpScale{
        width: 39.1%;
        margin: 0 auto 5.5vw;
    }
}

.footer-company__data{
    font-size: 0.875vw;
    line-height: 2.14;
    margin-bottom: 4.1vw;

    @include SpScale{
        font-size: 3.2vw;
        line-height: 1.41;
        text-align: center;
        margin-bottom: 6.5vw;
    }
}

.footer-company__copyright{
    font-size: 0.875vw;

    @include SpScale{
        font-size: 2.4vw;
        text-align: center;
    }
}

.footer-contents-list{
    -webkit-box-flex: 0;
    flex: 0 0 66.73%;
    display: flex;
    flex-wrap: wrap;

    @include SpScale{
        flex: 0 0 50%;
    }

    &:nth-child(1){
        .footer-contents-list__item{
            flex: 0 0 50%;

            @include SpScale{
                flex: 0 0 100%;
            }

            &:nth-child(3){
                dt{
                    @include SpScale{
                        margin-bottom: 1.9vw;
                    }
                }
            }

            &:last-child{
                dd{
                    color: #000;

                    a,a:link,a:visited{
                        color: #000;
                    }
                }
            }
        }
    }

    &:nth-child(2){
        flex: 0 0 33.36%;

        @include SpScale{
            flex: 0 0 50%;
        }

        .footer-contents-list__item{
            dt{
                flex: 0 0 100%;
            }

            dd{
                flex: 0 0 100%;
                padding-left: 1em;
                text-indent: -0.62em;

                &.footer-list-item__column{
                    flex: 0 0 47.31%;

                    @include SpScale{
                        flex: 0 0 100%;
                        line-height: 1.3;
                        margin-bottom: 1.5vw;
                    }

                    &.footer-list-item__column-indent{
                        padding-left: 1.7em;
                        text-indent: -0.5em;
                    }

                    &.footer-list-item__column-2{
                        margin-bottom: 1.9vw;

                        @include SpScale{
                            margin-bottom: 1.5vw;
                        }
                    }

                    &.footer-list-item__column-3{
                        margin-top: -0.75vw;

                        @include SpScale{
                            margin-top:0 ;
                            margin-bottom: 5.5vw;
                        }
                    }

                    &.footer-list-item__column-5{
                        margin-bottom: 1.5vw;

                        @include SpScale{
                            margin-bottom: 2vw;
                        }
                    }
                }

                &.footer-list-item__column-6{
                    margin-bottom: 1.9vw;

                    @include SpScale{
                        margin-top: 4vw;
                        margin-bottom: 3.3vw;
                    }
                }

                &:nth-child(6){

                    @include SpScale{
                        margin-bottom: 4.5vw !important;
                    }
                }
            }
        }
    }

    &:nth-child(3){
        @include SpScale{
            flex: 0 0 100%;
            padding-top: 13.1vw;

            .footer-list-item__inner{
                dd{
                    flex: 0 0 50%;
                    color: #000;

                    a,a:link,a:visited{
                        color: #000;
                    }
                }
            }
        }
    }
}

.footer-contents-list__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2.44vw;

    @include SpScale{
        margin-bottom: 7.5vw;
    }

    &:nth-child(5){
        .footer-list-item__inner{
            dd{
                flex: 0 0 100%;
            }
        }
    }

    &:last-child{
        margin-bottom: 0;

        .footer-list-item__inner{
            &:nth-child(2){
                dd{
                    flex: 0 0 100%;
                }
            }
        }
    }
}

.footer-list-item__inner{
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    dt{
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        font-size: 1vw;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin-bottom: 1.8vw;

        @include SpScale{
            font-size: 3.75vw;
            margin-bottom: 6.5vw;
        }

        a,a:link,a:visited{
            color: #000;
            @include blink;
        }


    }

    dd{
        -webkit-box-flex: 0;
        flex: 0 0 45.11%;
        font-size: 0.875vw;
        margin-bottom: 1.05vw;
        color: #666666;

        @include SpScale{
            flex: 0 0 100%;
            font-size: 3.45vw;
            margin-bottom: 2.8vw;
            padding-left: 0;
        }

        &:nth-child(2n+1){
            flex: 0 0 calc(100% - 45.11%);

            @include SpScale{
                flex: 0 0 100%;
            }
        }

        &:before{
            content:">";
            padding-right: 0.25em;
            transform: scaleX(0.7);
            display: inline-block;
        }

        &.footer-list-item__column{

        }

        a,a:link,a:visited{
            color: #666666;
            text-decoration: none;
            @include blink;
        }
    }
}

.footer-bottom{
    width: 100%;
    overflow: hidden;
}

.footer-bottom__pic{
    width: 100%;
    height: 17.313vw;
    display: flex;
    overflow: hidden;

    div{
        height: 100%;
        transition: transform 1s cubic-bezier(.95,.1,.23,.99);
        transition-delay: calc(var(--move-delay)*0.07s);
        transform: translateY(-100%);

        img{
            width: auto;
            height: 100%;
        }
    }

    &.show{
        div{
            transform: translateY(0);
        }
    }
}


