@charset "UTF-8";
@import "_property.scss";

/*
リセット
/***********************/


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}



html {
	line-height: 1;

}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

caption, th, td {
	text-align: left;
	font-weight: normal;
	vertical-align: middle;
}



q, blockquote {
	quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
	content: "";
	content: none;
}

a,a:hover{
    text-decoration: none;
}

a>img,nav>img,li>img,div>img{
    width:100%;
    height: auto;
}

a img {
	border: none;
}

a {
	cursor:pointer;
	text-decoration: none;
	color: #000;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}

button{
    appearance: none;
    -webkit-appearance: none;
    border: none;
    padding: 0;
}

@include PcScale{
    .sp_only{
       display:none !important;
    }
}

@include SpScale{
    body{
         font-size: 3.73vw;
    }

    .pc_only{
       display:none !important;
    }


}

.clearfix:after{
	font-size:0;
}

.clearfix { zoom: 1; }
.clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }


body{
	position:absolute;
	background-color: #fff;
	margin:0;
	padding:0;
	width:100%;
	height:100%;
    overflow: visible;

    @include SpScale{
        font-size:3.73vw;
    }
}

body *{
  box-sizing: border-box;
}

div,h1,h2,h3,h4,h5,ul,ol,li,dl,dt,dd{
position: relative;
}


h1,h2,h3,h4,h5{
	margin:0;
	padding:0;
	line-height: 1;
}



p,div,span{
	margin:0;
	padding:0;
	line-height: 1.5;
}

ul,ol,li,dl,dt,dd{
	margin:0;
	padding:0;
	list-style:none;
	line-height: 1;
}

img,
a img {
	border:none;
	display: inline-block;
}

 img{
    width: 100%;
    height: auto;
}


$pcMinW:1000px;

#wrapper{
	position: relative;
	width:100%;
    height: auto;
    @include SpScale{
    min-width: 100%;
        overflow: hidden;
    }
}



.pc{

}

.sp{
   display: none !important;
}

@include SpScale{
    .pc{
        display: none !important;
    }

    .sp{
       display: block !important;
    }
}



